@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

:root {
    /*========== Font and typography ==========*/
    --body-font: "Poppins", sans-serif;
    --sourcecode-font: "Source Code Pro", monospace;

    /*.5rem = 8px | 1rem = 16px ...*/
    --big-font-size: 3.5rem;
    --normal-font-size: 0.875remrem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.753rem;
    --tiny-font-size: 0.625rem;
}

#home, #aboutme, #contact, #project, #skills, #work-experiences {
    font-size: var(--small-font-size);
}

.navbar {
    background-color: black !important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1030;
}

.navbar .navbar-nav {
    float: none;
    margin: 0 auto;
    text-align: center;
}
  
.navbar .navbar-collapse {
    text-align: center;
}

.navbar .nav-item {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.section {
    min-height: 100vh;
    display: flex !important;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    flex-direction: column;
    padding-top: 20px;
}

.scroll-container {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
    transition: 0.5s;
}

.nav-link.active {
    color: white;
}

.navbar-icon {
    font-size: 1.0rem;
    margin-right: 0.5rem;
}

/*============================== Home Page ==============================*/
.home-name {
    font-weight: 500;
}

.tagline {
    color: aqua;
}

.btn-style {
    padding:10px 20px;
    color: white;
    background-color: black;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 500;
    align-items: center;
    display: flex;
    align-content: center;
}

.btn-style:hover {
    color: black;
    background-color: white;
    transition: color 0.4s, background-color 0.4s;
}

.btn-style i {
    margin-right: 10px;
    font-size: 1.0rem;
}

/*============================== About Me ==============================*/
#aboutme .profile-pic {
    width: 70%;
    height: 70%;
    clip-path: circle();
}

#aboutme p {
    text-align: justify;
}

#aboutme .nav-tabs {
    border-bottom: none;
}

#aboutme .nav-tabs .nav-link {
    border: none;
    background-color: transparent;
    color: white;
    display: inline-block;
    padding-bottom: 10px;
    position: relative;
}

#aboutme .nav-tabs .nav-link.active {
    border: none;
    background-color: transparent;
    color: white;
    display: inline-block;
    padding-bottom: 10px;
    position: relative;
}

#aboutme .nav-tabs .nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: aqua;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-in-out;
}

#aboutme .nav-tabs .nav-link.active::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

#aboutme .nav-tabs .nav-link:hover::after {
    transform: scaleX(0); 
}

.text-grey {
    color: grey;
    padding-bottom: 10px;
    font-weight: 500;
}

/*============================== Skills Page ==============================*/
#skills .button {
    color: white;
    background-color: black;
    font-size: var(--small-font-size);
    font-weight: 500;
    border: 1px solid #555555;
}

#skills .button.active {
    color: black;
    background-color: white;
    border: none;
}

#skills .button:hover {
    border: 1px solid white;
    transition: border 0.4s;
}

#skills .button.active:hover {
    border: none;
}

.skills-box {
    border: 2px solid #555555;
    border-radius: 20px;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: block;
    text-align: center;
}

.skills-box p {
    padding-left: 5px;
}

.active-skills-box {
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: block;
    text-align: center;
}

.icon-container {
    position: relative;
    display: inline-block;
    margin: 10px;
}

.icon-container img {
    width: 1.9rem;
}

.skills-box:hover {
    border: 2px solid white;
    transition: border 0.4s;
    cursor: pointer;
}

.icon-container .tooltip {
    font-size: var(--normal-font-size);
    font-weight: 500;
    visibility: hidden;
    width: 120px;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the icon */
    left: 50%;
    margin-left: -60px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.icon-container .tooltip::after {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.icon-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

#skills .soft-skills-icon {
    font-size: 1.3rem;
}

.skills-box h4 {
    padding-top: 20px;
    padding-bottom: 10px;
}

/*============================== Projects Page ==============================*/
#projects .carousel-item {
    top:5px;
    min-height: 450px !important;
}

.project-section {
    position: relative;
    width: 100%;
    max-width: 970px;
    margin: 0 auto;
}

.project-container {
    position: absolute;
    top: 80px;
    left: 50px;
    z-index: 10; /* Ensures the project container is above the image */
    max-width: 50%;
}

.project-info {
    padding: 10px 20px;
    background-color: #4169E1;
    color: white;
    border-radius: 10px;
    font-size: var(--smaller-font-size);
    box-shadow: 6px 6px 10px -5px black;
}

.project-description {
    font-size: var(--smaller-font-size);
    margin: 10px 0;
    text-align: justify;
}

.project-note {
    color: yellow;
    font-weight: bold;
}

.project-note-p a { 
    color: white;
    font-style: italic;
}

.project-image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 65%;
    padding-top: 10px;
}

.project-image img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}

.project-featured {
    color: aqua;
}

.project-title {
    padding-top: 5px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    max-width: 260px;
}

.project-featured a {
    font-size: 1.2rem !important;
    margin-left: 15px;
    background-color: transparent;
    color: white;
}

.project-title a:hover {
    color: cyan;
    transition: color 0.4s ease-in-out;
}

.project-languages div {
    display: inline-block;
    margin-top: 10px;
    margin-right: 40px;
    font-family: var(--sourcecode-font);
    font-weight: 500;
    color: white;
    font-size: var(--small-font-size);
}

/*============================== Work Experience Page ==============================*/
.vertical-line-icon {
    display: table;
    height: 100%;
}

.vertical-line-icon:after {
    content: '';
    display: table;
    margin: 0 7px;
    width: 2px;
    height: 90%;
    background-color: #555555;
    margin-top:5px;
}

.last-vertical-line:after {
    height: 65% !important;
}

.work-box {
    border: 2px solid #555555;
    border-radius: 20px;
    padding: 15px 20px;
    display: block;
    margin-left: 10px;
}

#work-experiences .grey-text {
    color: grey;
    font-weight: 500;
    font-style: italic;
    font-size:var(--smaller-font-size);
}

/*============================== Service Page ==============================*/
.service-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 400px;
    text-align: center;
    font-size: var(--normal-font-size);
}

.service-box h5 {
    margin-bottom: 15px;
    font-weight: 600;
}

.service-box p {
    font-size: 0.8rem;
}

.service-box button {
    display: block;
    justify-content: baseline;
    margin-top: 20px;
    background: white;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    width: 100%;
}

.service-box a {
    font-size: 0.85rem !important;
    text-decoration: none;
    color: black;
    font-weight: 600;
}

/*============================== Contact Page ==============================*/
.social-icon a {
    color: white;
    background-color: black;
    border: solid 2px white;
    border-radius: 50%;
    margin: 10px;
    padding: 15px;
    display: inline-flex;
    line-height: 1;
}

.social-icon a:hover {
    color: black;
    background-color: white;
    border: solid 2px white;
    transition: color 0.5s, background-color 0.5s;
}

.bigger-icon {
    font-size: 25px;
}

#contact form {
    background: black;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid white;
    font-size: var(--smaller-font-size) !important;
}

#contact form label {
    color: #bbb;
    font-size: var(--smaller-font-size) !important;
}

#contact form input, #contact form textarea {
    background: black;
    color: white;
    border: 1px solid white;
    font-size: var(--smaller-font-size) !important;
}

#contact form input::placeholder, form textarea::placeholder {
    color: grey !important;
    font-style: italic;
}

#contact form button {
    background: white;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 500;
    width: 100%;
    font-size: var(--normal-font-size) !important;
}

/*============================== Animation ==============================*/
@keyframes slideInFromTop {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
  
.nav-link {
    opacity: 0; /* Start hidden */
    animation: slideInFromTop 0.5s forwards; /* Slide in and keep the final state */
}
  
.nav-link:nth-child(1) {
    animation-delay: 0.3s;
}
  
.nav-link:nth-child(2) {
    animation-delay: 0.6s;
}

.nav-link:nth-child(3) {
    animation-delay: 0.9s;
}

.nav-link:nth-child(4) {
    animation-delay: 1.2s;
}

.nav-link:nth-child(5) {
    animation-delay: 1.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

#home h1, #home p, #home img, #home span, #home .btn-style, #home h3 {
    opacity: 0;
    animation: fadeIn 1s forwards;
}

#home h1, #home span, #home p, #home .btn-style, #home h3 {
    animation-delay: 1.5s; 
}

#home img {
    animation-delay: 2s;
}

.animated-picture {
    animation: slideIn 3s ease-in-out;
}

@media (max-width: 968px) {
    .section {
        height: auto;
        justify-content: center;
        align-items: center;
        scroll-snap-align: start;
        padding-top: 60px; /* Adjust for fixed navbar */
    }
    
    .scroll-container {
        overflow-y: auto;
        scroll-snap-type: none;
        -webkit-overflow-scrolling: none;
        transition: 0.5s;
    }

    .home-left-section {
        text-align: center;
    }

    .home-left-section button {
        margin-left: auto;
        margin-right: auto;
    }
    
    .active-skills-box {
        margin-top: 10px !important;
        margin-bottom: 5px !important;
    }

    .skills-box {
        width: 70% !important;
        margin-top: 10px !important;
        margin-bottom: 5px !important;
    }

    .project-section {
        height: auto; /* Adjust height for smaller screens */
        padding: 20px;
    }

    .project-container {
        position: relative;
        top: 0;
        left: 0;
        max-width: 100%;
        padding: 20px;
        margin-bottom: 20px;
    }

    .project-image {
        display: none;
    }

    .project-title {
        font-size: 1.5em;
    }

    .project-title a {
        padding-left: 10px;
        color: white;
    }

    .project-title button {
        font-size: 1.5rem !important;
    }

    .carousel {
        display: none; /* Hide the carousel */
    }

    .project-container {
        display: block;
        margin-bottom: 20px;
    }

    .aboutme-col {
        margin-left: 25px;
        margin-right: 25px;
    }
}

